import {setFunctionOnScroll} from "./modules/setFunctionOnScroll";
import {Line} from "./modules/Line";
import {Form} from "./modules/Form";

document.addEventListener('DOMContentLoaded', function () {
    // Обработчик нажатий по номеру телефона (для метрик)
    const phoneLinks = document.querySelectorAll('a.phone-link');
    phoneLinks.forEach(function (phoneLink) {
        phoneLink.addEventListener('click', function () {
            (() => {
                gtag('event', 'sendemail', {'event_category': 'nomer', 'event_action': 'send',});
                return true;
            })();
            (() => {
                yaCounter62247808.reachGoal('nomer');
                return true;
            })();
        })
    });

    // Обработчик клика по якорям
    const anchorLink = document.querySelectorAll('a.anchor-link');


    function inputMask() {
        $('.input-country').mask('+9?99');
        $('.input-phone').mask('(999) 999-99-99', {autoclear: false});
    }

    inputMask();

    function prevDef(e) {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    }


    $.fancybox.defaults.animationEffect = "fade";

    const fanc = {
        open: function (content, type = 'inline') {
            $.fancybox.open({
                src: `<div class="scroll-wrapper">
					${content}
					</div>`,
                type: type,
                opt: {},
                touch: false,
            })
        },
        openForm: function (data) {
            $('#form-container').find('.form-info').val(data);

            $.fancybox.open($('#hidden-form').find('.hidden-form-wrapper'), {
                touch: false,
                autoFocus: false
            });
        },
        openGallery: function (num, imgArr, caption, currImg = false) {
            let plantPhoto = [];
            let index = 0;
            const img = $(`[data-num]`, imgArr);
            let currentPhoto = $('img', $(`[data-num=${num}]`, imgArr)[0])[0];
            if (currImg) {
                currentPhoto = currImg[0];
            }

            $(img).each(function (i, el) {
                const ci = $('img', el)[0];
                let currSrc = currentPhoto.src;
                if (!currSrc) currSrc = currentPhoto.dataset.src;

                let ciSrc = ci.src;
                if (!ciSrc) ciSrc = ci.dataset.src;

                if (currSrc.indexOf(ciSrc) !== -1) {
                    index = i;
                }

                const currNum = el.dataset.num;
                const elem = {
                    src: $('a', el).attr('href'),
                    opts: {
                        caption: caption(currNum),
                    }
                }
                plantPhoto.push(elem);
            })

            $.fancybox.close();
            $.fancybox.open(plantPhoto, {}, index);
        }
    }

    $('.button-click').click(function () {
        const data = $(this).data('info');
        fanc.openForm(data);
    });

    $('.navbar-messenger').click(function () {
        var mesWindow = $('.messenger-number');
        mesWindow.addClass('messenger-number-active');
        $('.messenger-number__close').click(function () {
            $('.messenger-number').removeClass('messenger-number-active');
        })
    });

    $('.price-type').click(function () {
        $('.price-type').removeClass('active');
        $('.price-wrapper').removeClass('active');
        $(this).addClass('active');
        const typeNum = this.dataset.item;
        $('.price-wrapper[data-type=' + typeNum + ']').addClass('active');
    });

    $('.language-swich').click(function () {
        $(this).toggleClass('language-swich-active')
    });

    function mapsScroll() {
        if ($(window).scrollTop() > $('#reasons').offset().top) {
            $('body, html').unbind('scroll', mapsScroll);

        }
    }

    $('form').submit(function (e) {
        prevDef(e);
        const form = new Form(this);
        const {success, errorFields} = form.validate();
        if (!success) {
            errorFields.forEach(function (field) {
                if (field.inputName === 'phone') {
                    console.log(new Error(field.errorMessage));
                    alert('Пожалуйста, введите правильный номер телефона!');
                    form.form.elements[field.inputName].style.border = '1px solid red';
                }
            });

            return false;
        }

        form.send();
    });

    $('.advantages-elem__title').click(function () {
        $('.advantages-elem').removeClass('active');
        $(this).parents('.advantages-elem').addClass('active');
    });

    $('.types-elem__title').click(function () {
        $('.types-elem').removeClass('active');
        $(this).parents('.types-elem').addClass('active');
    });

    const menu = {
        but: $('#burger'),
        body: $('#menu'),
        opened: false,
        open: function () {
            this.but.addClass('active');
            this.body.addClass('active');
            this.opened = true;
        },
        close: function () {
            this.but.removeClass('active');
            this.body.removeClass('active');
            this.opened = false;
        },
    };

    $('.anchor').click(function (e) {
        prevDef(e);
        const href = $(this).attr('href');
        const topPanelHeight = $('#nav-panel').height();
        const destination = $(href).offset().top - topPanelHeight;
        menu.close();
        $("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, 800);

        return false;
    });

    function menuClick(e) {
        if (menu.but.is(e.target) || menu.but.has(e.target).length > 0) {
            menu.opened ? menu.close()
                : menu.open();
        } else {
            if (!menu.body.is(e.target) && menu.body.has(e.target).length === 0) {
                menu.close();
            }
        }
    }

    document.addEventListener('click', function (e) {
        menuClick(e);
    });

    if (window.innerWidth >= 1260) {
        setFunctionOnScroll($('#scheme').offset().top - 300, function () {
            const line = new Line(document.querySelector(".scheme-main-block"));

            $(".scheme-elem").each(function () {
                const num = +this.dataset.schemefrom;

                const circle = $(this).find(".scheme-elem__img").get(0);
                const width = circle.offsetWidth;
                const top = circle.offsetTop;
                const left = circle.offsetLeft + width / 2;

                const dotTo = $(".scheme-main-block").find("[data-schemeto=" + num + "]").get(0);

                const topLineDefaultSize = 15;

                const dotXSize = dotTo.offsetLeft + dotTo.offsetWidth / 2 - left;
                const dotYSize = dotTo.offsetTop + dotTo.offsetHeight / 2 - top + topLineDefaultSize;

                const line1 = line.createLine(top, left, Math.PI / 2, topLineDefaultSize);

                switch (num) {
                    case 1: {
                        const line2 = line.createLine(line1.top, line1.left, Math.PI, dotXSize);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        break;
                    }
                    case 2: {
                        const line2 = line.createLine(line1.top, line1.left, 0, -dotXSize);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        break;
                    }

                    case 3: {
                        const line2 = line.createLine(line1.top, line1.left, Math.PI, dotXSize);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        break;
                    }

                    case 4: {
                        const line2 = line.createLine(line1.top, line1.left, 0, -dotXSize - 30);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, 0, 30);
                        break;
                    }

                    case 5: {
                        const line2 = line.createLine(line1.top, line1.left, Math.PI, dotXSize);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        break;
                    }

                    case 6: {
                        const line2 = line.createLine(line1.top, line1.left, 0, -dotXSize - 80);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, 0, 80);
                        break;
                    }

                    case 7: {
                        const line2 = line.createLine(line1.top, line1.left, Math.PI, dotXSize - 120);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, Math.PI, 120);
                        break;
                    }

                    case 8: {
                        const line2 = line.createLine(line1.top, line1.left, 0, -dotXSize - 80);
                        const line3 = line.createLine(line2.top, line2.left, Math.PI / 2, -dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, 0, 80);
                        break;
                    }

                    case 9: {
                        const line2 = line.createLine(line1.top, line1.left, Math.PI, dotXSize - 120);
                        const line3 = line.createLine(line2.top, line2.left, -Math.PI / 2, dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, Math.PI, 120);
                        break;
                    }

                    case 10: {
                        const line2 = line.createLine(line1.top, line1.left, 0, -dotXSize - 50);
                        const line3 = line.createLine(line2.top, line2.left, Math.PI / 2, -dotYSize);
                        const line4 = line.createLine(line3.top, line3.left, 0, 50);
                        break;
                    }
                }
            });
        });
    }

    if ($('#films').length) {
        setFunctionOnScroll($("#price").offset().top, function() {
        new Splide('#films .splide', {
            type: 'loop',
            perPage: 3,
            speed: 200,
            perMove: 1,
            autoHeight: true,
            breakpoints: {
                800: {
                    perPage: 2
                },
                500: {
                    perPage: 1
                }
            }
        }).mount();
    });
    }

    new Splide('#gallery-slider .splide', {
            type: 'loop',
            perPage: 3,
            speed: 200,
            perMove: 1,
            autoHeight: true,
            breakpoints: {
                800: {
                    perPage: 2
                },
                400: {
                    perPage: 1
                }
            }
        }).mount();
});