export class Line {
    constructor(parent, params = {}) {
        this.parent = parent;
        this.className = params.className || parent.className + "__line";
        this.margin = params.margin || 10;
        this.direction = params.direction || "top";
        this.lineDecoration = params.lineDecoration || "dashed";
        this.lineWidth = params.lineWidth || 2;
        this.lineColor = params.lineColor || "rgba(241,90,5,0.7)";

        // время, за которое создается 1px в линии
        this.animationDuration = 10;
        this.parent.style.position = "relative";
    }

    createLine(top, left, rotate, size) {
        const line = document.createElement("div");
        line.classList.add(this.className);
        line.style.position = "absolute";
        this.parent.appendChild(line);

        const {lineWidth, lineDecoration, lineColor} = this;

        const lineX = Math.sin(rotate).toFixed(2) * size;
        const lineY = Math.cos(rotate).toFixed(2) * size;

        const rTop = top - lineX;
        const rLeft = left - lineY;

        line.style.borderTop = `${lineWidth}px ${lineDecoration} ${lineColor}`;

        line.style.transform = "rotate(" + Math.round(rotate * 180 / Math.PI) + "deg)";
        line.style.top = (top - lineX / 2) + "px";
        line.style.left = (left - lineY / 2 - size / 2) + "px";

        line.style.width = size + "px";

        return {top: rTop, left: rLeft};
    }
}