export function setFunctionOnScroll(top, callback) {
    if ($(window).scrollTop() >= top) {
        callback();
        return false;
    }

    $(window).scroll(scr);

    function scr() {
        if ($(window).scrollTop() >= top) {
            $(window).off('scroll', scr);
            callback();
        }
    }
}