import {Validator} from "./Validator";

export class Form {
    constructor(form) {
        this.form = form;
        this.name = form.elements.name.value;
        this.phoneCountry = form.elements.phoneCountry.value || '+7';
        this.phone = form.elements.phone.value;
        this.method = form.method;
        this.url = form.action;

        this.csrftoken = form.elements.csrfmiddlewaretoken.value;
    }

    validate() {
        let success = true;
        let errorFields = [];

        if (!Validator.phone(this.phone)) {
            success = false;
            errorFields.push({
                inputName: 'phone',
                errorMessage: 'Wrong phone number length',
            });
        }

        return {success, errorFields};
    }

    send() {
        const data = {
            name: this.name,
            phone: this.phoneCountry + ' ' + this.phone
        };
        $.ajax({
            type: this.method,
            url: this.url,
            headers: {
                'X-CSRFToken': this.csrftoken,
            },
            data: data,
            success: function (rs) {
                console.log(rs);
                if (rs.success) {
                    this.form.elements.phone.style.border = '1px solid #E5E5E5';
                    $.fancybox.close();
                    $.fancybox.open({
                        modal: true,
                        src: '#success'
                    });
                    (function () {
                        gtag('event', 'sendemail', {'event_category': 'otpravit', 'event_action': 'send',});
                        return true;
                    })();
                    (function () {
                        yaCounter62247808.reachGoal('otpravit');
                        return true;
                    })();
                } else {
                    this.form.elements.phone.style.border = '1px solid red';
                    alert("Пожалуйста, введите правильный номер телефона!");
                }
            }.bind(this),
            error: function(er) {
                console.log(er);
            }
        });
    }
}